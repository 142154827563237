import { action, makeObservable, observable } from "mobx";

class VehicleLookupStore {
  isButtonLoading = false;
  registrationEmptyError = false;
  infoCardVisible = false;
  showManualControls = false;
  vehicleLookupPartialResults = false;
  registrationLookupDetails = "";
  showRegNotFoundError = false;
  showInfoMessage = true;

  constructor() {
    makeObservable(this, {
      registrationLookupDetails: observable,
      isButtonLoading: observable,
      registrationEmptyError: observable,
      showRegNotFoundError: observable,
      showInfoMessage: observable,
      infoCardVisible: observable,
      showManualControls: observable,
      vehicleLookupPartialResults: observable,
      setRegistrationLookupDetails: action,
      setIsButtonLoading: action,
      setRegistrationEmptyError: action,
      setShowRegNotFoundError: action,
      setShowInfoMessage: action,
      setInfoCardVisibility: action,
      setShowManualControls: action,
      setVehicleLookupPartialResults: action,
    });
  }

  setIsButtonLoading = (newValue) => {
    this.isButtonLoading = newValue;
  };

  setRegistrationEmptyError = (newValue) => {
    this.registrationEmptyError = newValue;
  };

  setShowRegNotFoundError = (newValue) => {
    this.showRegNotFoundError = newValue;
  };

  setShowInfoMessage = (newValue) => {
    this.showInfoMessage = newValue;
  };

  setInfoCardVisibility = (newValue) => {
    this.infoCardVisible = newValue;
  };

  setShowManualControls = (newValue) => {
    this.showManualControls = newValue;
  };

  setVehicleLookupPartialResults = (newValue) => {
    this.vehicleLookupPartialResults = newValue;
  };

  setRegistrationLookupDetails = (newValue) => {
    this.registrationLookupDetails = newValue;
  };

  getProduct = () => {
    return import.meta.env.VITE_PRODUCT?.toLowerCase();
  };
}

export const vehicleLookupStore = new VehicleLookupStore();
