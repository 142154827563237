import dayjs from "dayjs";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ControlRowFactory from "../../factories/ControlRowFactory";
import ControlsFactory from "../../factories/ControlsFactory";
import helper from "../../helpers/controlsLogicHelper";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import AppStore from "../../store/AppStore";
import Error from "../controls/StaticControls/Error.Component";
import Info from "../controls/StaticControls/Info.Component";
import Label from "../controls/StaticControls/Label.Component";
import Tooltip from "../controls/StaticControls/Tooltip.Component";
import TextArea from "./StaticControls/TextArea.Component";

const Dropdown = observer(
  ({
    id,
    name,
    label,
    controlValuesList,
    handleChange,
    handleBlur,
    handleInteraction,
    currentValue,
    defaultValue,
    helpMessages,
    infoMessages,
    extraInfo,
    children,
    validation,
    validationResult,
    logicalName,
    attributes,
  }) => {
    const customFormGroupClass = attributes?.customFormGroupClass ?? "";
    const liveValidation = AppStore.liveValidation;
    const [currentControlValuesList, setCurrentControlValuesList] = useState(
      controlValuesList || []
    );
    let [businessEstablishedDisabled, setbusinessEstablishedDisabled] = useState(false);
    let subControls = {};
    children.map((item) => {
      let controlName = item.Name;
      if (item.Name === "business-not-established") {
        let control = (
          <ControlsFactory
            model={item}
            key={item.Name}
            setDisableControl={(ev) => {
              setbusinessEstablishedDisabled(ev.currentTarget.checked);
            }}
            validationResult={validationResult}
          />
        );
        subControls[controlName] = control;
        let businessEstablishedValue = AppStore.getControlByName(
          "business-not-established"
        );
        businessEstablishedDisabled = isNullOrUndefined(
          businessEstablishedValue === undefined
        )
          ? false
          : businessEstablishedValue;
      } else {
        let control = (
          <ControlsFactory
            model={item}
            key={item.Name}
            validationResult={validationResult}
          />
        );
        subControls[controlName] = control;
      }
    });

    const highlightInfoClass =
      infoMessages?.info.class ||
      ((logicalName === "driving-licence-bike" ||
        logicalName === "driving-licence-bike-additional") &&
        !isNullOrUndefined(infoMessages))
        ? "info-highlight"
        : "";

    const [isInfoVisible, setIsInfoVisible] = useState(true);
    const errorClass = validation && validation.isValid === false ? "error" : "";
    const customCssClass = "col-lg-4 col-md-5";
    const errorNextLineClass =
      name === "cause-or-description" ||
      name === "driving-licence" ||
      name === "preferred-payment" ||
      name === "driving-licence-additional"
        ? "next-line"
        : "";

    let widthType =
      attributes !== null
        ? attributes.widthType !== null || attributes.widthType !== "undefined"
          ? attributes.widthType
          : ""
        : "";

    let controlCustomCss =
      widthType === "small"
        ? "col-lg-3 col-md-4"
        : widthType === "large"
        ? "col-lg-8 col-md-8"
        : `${customCssClass}`;

    let customDropdownWidth =
      name === "preferred-payment" && widthType === "large" ? customCssClass : "";

    let [isControlVisible, setControlVisibility] = useState(true);
    let [licenceCarType, setLicenceCarType] = useState(false);
    let dob = AppStore.getControlByName("date-of-birth");
    let age = dayjs().diff(dob, "years");
    let dobAdditional = AppStore.getControlByName("date-of-birth-additional");
    let ageAdditional = dayjs().diff(dobAdditional, "years");

    const updateLicenceTypeOptions = (optionsList) => {
      setCurrentControlValuesList(optionsList);

      if (!optionsList.find((v) => v.Key === currentValue)) {
        AppStore.setFormData({ [name]: "" });
      }
    };

    useEffect(() => {
      if (age < 17) {
        if (name === "type-car") {
          setLicenceCarType(true);
          AppStore.setFormData({ [name]: "N" });
        }
      }
      if (
        logicalName === "driving-licence-bike" ||
        logicalName === "driving-licence-bike-additional"
      ) {
        if (currentValue === "M") {
          setIsInfoVisible(true);
        } else {
          setIsInfoVisible(false);
        }
      }
    }, [age]);

    useEffect(() => {
      if (name === "title" || name === "title-additional") {
        if (currentValue === "Mx" || currentValue === "Dr (Unspecified)") {
          setIsInfoVisible(true);
        } else {
          setIsInfoVisible(false);
        }
      }
    }, [currentValue]);

    let [differentAddressVisible, setDifferentAddressVisible] = useState(
      currentValue === "HLG" ||
        currentValue === "AHD" ||
        currentValue === "HDB" ||
        currentValue === "HCP" ||
        currentValue === "HR"
    );
    let [caravanStorageVisible, setCaravanStorageVisible] = useState(
      currentValue === "CS" || currentValue === "NSC"
    );
    let [cassoaVisible, setCassoaVisible] = useState(
      currentValue === "CSITE453" ||
        currentValue === "CSITE456" ||
        currentValue === "CSITE452" ||
        currentValue === "0"
    );
    let [numberOfCaravansVisible, setNumberOfCaravansVisible] = useState(
      currentValue === "CS" ||
        currentValue === "NSC" ||
        currentValue === "CP" ||
        currentValue === "WF" ||
        currentValue === "NF" ||
        currentValue === "O"
    );
    let [perimeterSecurityVisible, setPerimeterSecurityVisible] = useState(
      currentValue === "NSC" ||
        currentValue === "CP" ||
        currentValue === "WF" ||
        currentValue === "NF" ||
        currentValue === "O"
    );

    let touringSpecificControls = [
      "touring-caravan-type",
      "touring-caravan-manufacturer",
      "caravan-axle",
      "touring-storage",
      "touring-alarms",
      "tracker",
      "wheel-clamp",
      "hitch-lock",
      "caravan-permanently-sited",
      "ncb-driving",
      "touring-caravan-serial-number",
      "towing",
      "number-of-months",
    ];

    let staticSpecificControls = [
      "static-floatation-device",
      "static-caravan-manufacturer",
      "static-anchored-4points",
      "static-caravan-width",
      "static-caravan-length",
      "number-of-beds",
      "static-storage",
      "static-site-name",
      "static-alarms",
      "static-security",
      "ncb-household",
      "static-caravan-serial-number",
      "static-storage-damage",
    ];
    let caravanStaticDropdownControls = [
      "number-of-beds",
      "perimeter-security",
      "number-of-caravans",
      "static-alarms",
      "static-security",
      "caravan-usage",
    ];

    const setBeginnerLicenceValues = () => {
      updateLicenceTypeOptions([
        { Key: "", Value: "" },
        { Key: "P", Value: "Provisional UK" },
        { Key: "M", Value: "Moped" },
      ]);
    };

    const setLicenceOptionsDefault = () => {
      updateLicenceTypeOptions(controlValuesList || []);
    };

    useLayoutEffect(() => {
      if (
        (!!currentControlValuesList && isNullOrUndefined(currentValue)) ||
        currentValue.length === 0 ||
        currentValue === "NONE"
      ) {
        AppStore.setFormData({
          [name]:
            defaultValue === "" || isNullOrUndefined(defaultValue)
              ? currentControlValuesList.length > 0
                ? currentControlValuesList[0].Key
                : ""
              : defaultValue,
        });
      }
    }, []);

    useEffect(() => {
      if (logicalName === "driving-licence-bike") {
        if (age && age < 17) {
          setBeginnerLicenceValues();
        } else {
          setLicenceOptionsDefault();
        }
      }

      if (logicalName === "driving-licence-bike-additional") {
        if (ageAdditional && ageAdditional < 17) {
          setBeginnerLicenceValues();
        } else {
          setLicenceOptionsDefault();
        }
      }

      helper.ManipulateControlValues(name, currentValue);
    }, [age, ageAdditional]);

    useEffect(() => {
      if (AppStore.getControlByName("caravan-type") === "Touring") {
        touringSpecificControls.forEach((touringSpecificControl) => {
          AppStore.setVisibilityControls({ [touringSpecificControl]: true });
        });

        staticSpecificControls.forEach((staticSpecificControl) => {
          AppStore.setVisibilityControls({ [staticSpecificControl]: false });
          AppStore.deleteFormData(staticSpecificControl);
        });

        setNumberOfCaravansVisible(false);
        AppStore.deleteFormData("number-of-caravans");
        AppStore.setVisibilityControls({ ["number-of-caravans"]: false });

        if (AppStore.getControlByName("caravan-permanently-sited") === "no") {
          AppStore.deleteFormData("number-of-months");
          AppStore.setVisibilityControls({ ["number-of-months"]: false });
        }

        if (AppStore.getControlByName("touring-storage") === "CS") {
          setCassoaVisible(true);
          AppStore.setVisibilityControls({ ["cassao"]: true });

          if (
            AppStore.getControlByName("cassoa") === "CSITE453" ||
            AppStore.getControlByName("cassoa") === "CSITE456" ||
            AppStore.getControlByName("cassoa") === "CSITE452" ||
            AppStore.getControlByName("cassoa") === "0"
          ) {
            setCaravanStorageVisible(true);
            AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: true });
          } else {
            setCaravanStorageVisible(false);
            AppStore.deleteFormData("caravan-storage-postcode");
            AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: false });

            setNumberOfCaravansVisible(true);
            AppStore.setVisibilityControls({ ["number-of-caravans"]: true });
          }
        } else if (
          AppStore.getControlByName("touring-storage") === "NSC" ||
          AppStore.getControlByName("touring-storage") === "CP" ||
          AppStore.getControlByName("touring-storage") === "WF" ||
          AppStore.getControlByName("touring-storage") === "NF" ||
          AppStore.getControlByName("touring-storage") === "O"
        ) {
          setCaravanStorageVisible(true);
          AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: true });

          setPerimeterSecurityVisible(true);
          AppStore.setVisibilityControls({ ["perimeter-security"]: true });

          setNumberOfCaravansVisible(true);
          AppStore.setVisibilityControls({ ["number-of-caravans"]: true });
        } else if (
          AppStore.getControlByName("touring-storage") === "HLG" ||
          AppStore.getControlByName("touring-storage") === "AHD" ||
          AppStore.getControlByName("touring-storage") === "HDB" ||
          AppStore.getControlByName("touring-storage") === "HCP" ||
          AppStore.getControlByName("touring-storage") === "HR"
        ) {
          if (AppStore.getControlByName("different-address") === "yes") {
            setCaravanStorageVisible(true);
            AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: true });
          } else {
            setCaravanStorageVisible(false);
            AppStore.deleteFormData("caravan-storage-postcode");
            AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: false });
          }
        } else {
          setCaravanStorageVisible(false);
          AppStore.deleteFormData("caravan-storage-postcode");
          AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: false });

          setPerimeterSecurityVisible(false);
          AppStore.deleteFormData("perimeter-security");
          AppStore.setVisibilityControls({ ["perimeter-security"]: false });

          setNumberOfCaravansVisible(false);
          AppStore.deleteFormData("number-of-caravans");
          AppStore.setVisibilityControls({ ["number-of-caravans"]: false });
        }
      }

      if (AppStore.getControlByName("caravan-type") === "Static") {
        staticSpecificControls.forEach((staticSpecificControl) => {
          AppStore.setVisibilityControls({ [staticSpecificControl]: true });
        });

        touringSpecificControls.forEach((touringSpecificControl) => {
          AppStore.setVisibilityControls({ [touringSpecificControl]: false });
          AppStore.deleteFormData(touringSpecificControl);
        });

        setPerimeterSecurityVisible(true);
        AppStore.setVisibilityControls({ ["perimeter-security"]: true });

        setNumberOfCaravansVisible(true);
        AppStore.setVisibilityControls({ ["number-of-caravans"]: true });

        setCaravanStorageVisible(true);
        AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: true });
      }

      if (
        name === "perimeter-security" &&
        AppStore.getControlByName("caravan-type") === "Static"
      ) {
        if (!isNullOrUndefined(helpMessages)) {
          helpMessages.help.title = "Perimeter Security";
          helpMessages.help.message = `Please choose the type of fencing that is used around 
                                    the perimeter of the site where your caravan is kept.
                                    You should only select a type of fencing if it is used as a
                                    secure perimeter, with access only available via specific gates.`;
        }
      }

      if (
        (AppStore.getControlByName("caravan-type") === "Touring" &&
          ["NSC", "CP", "WF", "NF", "O"].includes(
            AppStore.getControlByName("touring-storage")
          )) ||
        (AppStore.getControlByName("caravan-type") === "Static" &&
          ["HD", "HA", "RS", "SC", "CC", "FSC", "O", ""].includes(
            AppStore.getControlByName("static-storage")
          ))
      ) {
        AppStore.setVisibilityControls({ ["cctv"]: true });
        AppStore.setVisibilityControls({ ["security-gates"]: true });
        AppStore.setVisibilityControls({ ["full-time-warden"]: true });
      } else if (
        name !== "static-alarms" ||
        name !== "static-security" ||
        name !== "caravan-usage"
      ) {
        AppStore.deleteFormData("cctv");
        AppStore.setVisibilityControls({ ["cctv"]: false });

        AppStore.deleteFormData("security-gates");
        AppStore.setVisibilityControls({ ["security-gates"]: false });

        AppStore.deleteFormData("full-time-warden");
        AppStore.setVisibilityControls({ ["full-time-warden"]: false });
      }
      helper.ManipulateControlValues(name, currentValue);
    }, []);

    let relationToProposerText =
      !isNullOrUndefined(AppStore.getUserJourneySettings().relationToProposer) &&
      !isNullOrUndefined(AppStore.getUserJourneySettings().relationToProposer.text)
        ? AppStore.getUserJourneySettings().relationToProposer.text === "true"
        : false;

    if (relationToProposerText && name === "relation-to-proposer") {
      let index = currentControlValuesList.findIndex(
        ({ Value }) => Value === "Common law partner"
      );
      if (index != -1) {
        currentControlValuesList[index].Value = "Common Law/Living with partner";
      }
    }

    const controlComponent = (
      <React.Fragment key={`controlkey-${name}`}>
        {attributes.prependText && (
          <div className="input-group-prepend">
            <span className="input-group-text" id="registration-image">
              {attributes.prependText}
            </span>
          </div>
        )}
        <select
          key={`controlkey-${name}`}
          data-testid={`test-${name}`}
          className={`form-control text-visible ${customDropdownWidth}`}
          id={name}
          name={name}
          onBlur={(ev) => {
            liveValidation && handleBlur(ev);
          }}
          onChange={(event) => {
            if (logicalName === "ncd-vehicle") {
              if (parseInt(event.target.value) >= 4) {
                AppStore.setVisibilityControls({ ["protected-ncd"]: true });
              } else {
                AppStore.setVisibilityControls({ ["protected-ncd"]: false });
              }
            }
            if (event.target.value === "NA") {
              AppStore.setVisibilityControls({ ["ncd-other-vehicles"]: false });
              AppStore.deleteFormData("ncd-other-vehicles");
            } else {
              AppStore.setVisibilityControls({ ["ncd-other-vehicles"]: true });
            }
            if (logicalName === "other-vehicles-mh") {
              if (
                event.target.value === "OC" ||
                event.target.value === "OV" ||
                event.target.value === "OM"
              ) {
                AppStore.setVisibilityControls({
                  ["ncd-other-vehicles-mh"]: true,
                });
              } else {
                AppStore.setVisibilityControls({
                  ["ncd-other-vehicles-mh"]: false,
                });
              }
            }
            if (logicalName === "driving-licence-car") {
              if (event.target.value === "F" || event.target.value === "P") {
                AppStore.setVisibilityControls({
                  ["driving-qualifications"]: true,
                });
              } else {
                AppStore.setVisibilityControls({
                  ["driving-qualifications"]: false,
                });
                AppStore.deleteFormData("driving-qualifications");
              }
            }
            if (
              logicalName === "driving-licence-bike" ||
              logicalName === "driving-licence-bike-additional"
            ) {
              if (event.target.value === "M") {
                setIsInfoVisible(true);
              } else {
                setIsInfoVisible(false);
              }
            }
            if (logicalName === "driving-licence-car-additional") {
              if (event.target.value === "F" || event.target.value === "P") {
                AppStore.setVisibilityControls({
                  ["driving-qualifications-additional"]: true,
                });
              } else {
                AppStore.setVisibilityControls({
                  ["driving-qualifications-additional"]: false,
                });
                AppStore.deleteFormData("driving-qualifications-additional");
              }
            }

            //#region - Caravan controls conditional data & visibility
            //This needs to be refactored and use either user journey actions(changeControlVisibility, dependentControlsRemovedForSelectedValue) or a different method

            if (
              (name === "static-storage" &&
                ["HD", "HA", "RS", "SC", "CC", "FSC", "O", ""].includes(
                  event.target.value
                )) ||
              (name === "touring-storage" &&
                ["NSC", "CP", "WF", "NF", "O"].includes(event.target.value))
            ) {
              AppStore.setVisibilityControls({ ["cctv"]: true });
              AppStore.setVisibilityControls({ ["security-gates"]: true });
              AppStore.setVisibilityControls({ ["full-time-warden"]: true });
            } else if (!caravanStaticDropdownControls.includes(name)) {
              AppStore.setVisibilityControls({ ["cctv"]: false });
              AppStore.deleteFormData("cctv");
              AppStore.setVisibilityControls({ ["security-gates"]: false });
              AppStore.deleteFormData("security-gates");
              AppStore.setVisibilityControls({ ["full-time-warden"]: false });
              AppStore.deleteFormData("full-time-warden");
            }

            if (name === "touring-storage") {
              if (
                event.target.value === "HLG" ||
                event.target.value === "AHD" ||
                event.target.value === "HDB" ||
                event.target.value === "HCP" ||
                event.target.value === "HR"
              ) {
                setDifferentAddressVisible(true);
                AppStore.setVisibilityControls({ ["different-address"]: true });

                if (AppStore.getControlByName("different-address") === "yes") {
                  setCaravanStorageVisible(true);
                  AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: true });
                } else {
                  setCaravanStorageVisible(false);
                  AppStore.deleteFormData("caravan-storage-postcode");
                  AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: false });
                }

                AppStore.setVisibilityControls({ ["cassoa"]: false });
                AppStore.deleteFormData("cassoa");

                setPerimeterSecurityVisible(false);
                AppStore.deleteFormData("perimeter-security");
                AppStore.setVisibilityControls({ ["perimeter-security"]: false });

                setNumberOfCaravansVisible(false);
                AppStore.deleteFormData("number-of-caravans");
                AppStore.setVisibilityControls({ ["number-of-caravans"]: false });
              } else if (event.target.value === "CS") {
                setCassoaVisible(true);
                AppStore.setVisibilityControls({ ["cassoa"]: true });

                setCaravanStorageVisible(false);
                AppStore.deleteFormData("caravan-storage-postcode");
                AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: false });

                setDifferentAddressVisible(false);
                AppStore.deleteFormData("different-address");
                AppStore.setVisibilityControls({ ["different-address"]: false });

                setPerimeterSecurityVisible(false);
                AppStore.deleteFormData("perimeter-security");
                AppStore.setVisibilityControls({ ["perimeter-security"]: false });

                setNumberOfCaravansVisible(true);
                AppStore.setVisibilityControls({ ["number-of-caravans"]: true });
              } else if (
                event.target.value === "NSC" ||
                event.target.value === "CP" ||
                event.target.value === "WF" ||
                event.target.value === "NF" ||
                event.target.value === "O"
              ) {
                setPerimeterSecurityVisible(true);
                AppStore.setVisibilityControls({ ["perimeter-security"]: true });

                setCaravanStorageVisible(true);
                AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: true });

                setDifferentAddressVisible(false);
                AppStore.deleteFormData("different-address");
                AppStore.setVisibilityControls({ ["different-address"]: false });

                setCassoaVisible(false);
                AppStore.deleteFormData("cassoa");
                AppStore.setVisibilityControls({ ["cassoa"]: false });

                setNumberOfCaravansVisible(true);
                AppStore.setVisibilityControls({ ["number-of-caravans"]: true });
              } else {
                setCaravanStorageVisible(false);
                AppStore.deleteFormData("caravan-storage-postcode");
                AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: false });

                setDifferentAddressVisible(false);
                AppStore.deleteFormData("different-address");
                AppStore.setVisibilityControls({ ["different-address"]: false });

                setCassoaVisible(false);
                AppStore.deleteFormData("cassoa");
                AppStore.setVisibilityControls({ ["cassoa"]: false });

                setPerimeterSecurityVisible(false);
                AppStore.deleteFormData("perimeter-security");
                AppStore.setVisibilityControls({ ["perimeter-security"]: false });

                setNumberOfCaravansVisible(false);
                AppStore.deleteFormData("number-of-caravans");
                AppStore.setVisibilityControls({ ["number-of-caravans"]: false });
              }
            }

            if (name === "cassoa") {
              if (
                event.target.value === "CSITE452" ||
                event.target.value === "CSITE453" ||
                event.target.value === "CSITE456" ||
                event.target.value === "0"
              ) {
                setCaravanStorageVisible(true);
                AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: true });
              } else {
                setCaravanStorageVisible(false);
                AppStore.deleteFormData("caravan-storage-postcode");
                AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: false });
              }
            }

            if (name === "caravan-type") {
              AppStore.setFormData({ "caravan-model": "" });
            }

            //#endregion - Caravan controls conditional data & visibility

            handleChange(event);
            handleBlur(event); // required for live validation
            handleInteraction(event);
            helper.ManipulateControlValuesOnChange(name, event.target.value);
          }}
          value={currentValue || defaultValue}
          disabled={
            businessEstablishedDisabled === true ||
            (name === "type-car" && licenceCarType)
              ? true
              : null
          }>
          {currentControlValuesList.map((item, index) => {
            return (
              <option
                value={item.Key}
                key={name + "_" + item.Key + "_" + index}
                data-testid={`test-${name}_${item.Key}`}
                className={
                  item.Key === "Top 10 Makes" ||
                  item.Key === "All Makes" ||
                  item.Key.includes("HEAD")
                    ? "head"
                    : ""
                }
                disabled={
                  item.Key === "Top 10 Makes" ||
                  item.Key === "All Makes" ||
                  item.Key.includes("HEAD")
                    ? true
                    : null
                }>
                {item.Value}
              </option>
            );
          })}
        </select>
      </React.Fragment>
    );

    const componentMapping = {
      label: (
        <Label
          key={`labelkey-${name}`}
          id={id}
          label={label}
          forInput={name}
          validation={validation ? validation : ""}
          defaultValue={defaultValue}
          hasIcon={attributes.hasIcon}
        />
      ), // forInput, validation and defaultValue are added for live validation purposes.
      tooltip: (
        <Tooltip
          key={`tooltipkey-${name}`}
          helpMessages={helpMessages != null ? helpMessages.help : ""}
          controlName={name}
        />
      ),
      info: (
        <>
          {extraInfo &&
            extraInfo.map(
              (ei) =>
                ei.text && (
                  <TextArea
                    key={`extrainfotextkey-${name}`}
                    name={`extrainfotextkey-${name}`}
                    label={ei.text}
                    className={"extraInfo"}
                  />
                )
            )}
          <Info
            key={`infokey-${name}`}
            infoMessages={infoMessages != null && isInfoVisible ? infoMessages.info : ""}
            className={highlightInfoClass}
          />
        </>
      ),
      error: (
        <Error
          key={`errorkey-${name}`}
          validation={validation ? validation : ""}
          className={errorNextLineClass}
        />
      ),
      control: controlComponent,
    };

    const groupClasses = {
      "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
      "form-group": `${controlCustomCss} col-sm-12 col-12`,
    };

    return (
      <React.Fragment>
        {isControlVisible && (
          <div
            className={`form-group form-group-container form-group-${name} ${customFormGroupClass}`}>
            <div id={`row-${name}`} className={`row row-${errorClass} `}>
              <ControlRowFactory
                controlName={name}
                groupClasses={groupClasses}
                componentMappings={componentMapping}
              />
            </div>
          </div>
        )}
        {differentAddressVisible && subControls["different-address"]}
        {cassoaVisible && subControls["cassoa"]}
        {caravanStorageVisible && subControls["caravan-storage-postcode"]}
        {numberOfCaravansVisible && subControls["number-of-caravans"]}
        {perimeterSecurityVisible && subControls["perimeter-security"]}
        {[subControls["business-not-established"]]}
      </React.Fragment>
    );
  }
);

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  controlValuesList: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string,
      Value: PropTypes.string,
    })
  ).isRequired,
};

export default Dropdown;
