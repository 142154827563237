import { preserveQueryStringParams } from "../AppLink";

const Banner = ({ bannerText }) => {
  return (
    <div className="banner-container">
      <div className="banner-row">
        <a
          href={preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/default/`)}
          className="banner-text">
          {bannerText}
        </a>
        <i className="banner-image"></i>
      </div>
    </div>
  );
};

export default Banner;
