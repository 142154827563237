import { vehicleLookupStore } from "@store/VehicleLookupStore";

export default Object.freeze({
  trackerBoolean: "tracker",
  tracker: "tracker-dropdown",
  markingsBoolean: "secure-markings",
  markings: "markings-dropdown",
  securityDevicesBoolean: "security-devices",
  securityDevices: "security-devices-dropdown",
  keeperCar: "registered-keeper",
  keeper: "register-keeper",
  owner: "legal-owner",
  keeperOwner: "keeper-owner",
  dvlaMedicalBoolean: "dvla-medical-boolean",
  dvlaMedical: "dvla-medical",
  businessNotEstablished: "business-not-established",
  businessEstablished: "business-established",
  offenceCode: "offence-code",
  alcoholReadingType: "type-of-alcohol-reading",
  alcoholReadingLevel: "level-of-reading",
  licenceType: "driving-licence",
  licenceTypeAdditional: "driving-licence-additional",
  dateOfBirth: "date-of-birth",
  engineCC: "engine-cc",
  yearManufacture: "year-manufacture",
  bikeType: "bike-type",
  purchaseDate: "purchase-date",
  overnightStorage: "overnight-storage",
  numberOfSeats: "number-of-seats",
  vehicleValue: "vehicle-value",
  optionalExtrasRadio: "optional-extras-radio",
  optionalExtrasSelect: "optional-extras-select",
  continueButtonVehicleDetails: "continue-button-VehicleDetails",
  causeOrDescription: "cause-or-description",
  causeOrDescriptionHelper: "cause-or-description-helper",
  dateOfIncident: "date-of-incident",
  dateOfConviction: "date-of-conviction",
  dateOfBirthAdditional: "date-of-birth-additional",
  firstNameAdditional: "first-name-additional",
  surnameAdditional: "surname-additional",
  firstName: "first-name",
  surname: "surname",
  surnameAdditional: "surname-additional",
  postcodeName: "proposer-postcode",
  postcodeDropdownControlName: "proposer-postcode-dropdown",
  postcodeManualControlName: "proposer-postcode-manual",
  houseNumberControlName: "proposer-postcode-house-number",
  houseNumberManualControlName: "proposer-house-number-name",
  streetControlName: "proposer-street-name",
  townControlName: "proposer-town",
  cityControlName: "proposer-city",
  countyControlName: "proposer-county",
  addressIsManualName: "button-address-is-manual",
  houseNameHiddenControlName: "proposer-house-name",
  houseNumberHiddenControlName: "proposer-house-number",
  officialukModelControlName: "official-uk-model",
  yearsLicenceHeld: "years-licence-held",
  licenceNotObtained: "licence-not-obtained",
  fuelType: "fuel-type",
  transmission: "transmission",
  hasCarLicenceBike: "car-licence-bike",
  NCBBike: "ncb-bike",
  NCDProtected: "protected-ncd",
  carOwner: "car-owner",
  vehicleRegistrationSearch: () =>
    `${vehicleLookupStore.getProduct()}-registration-search`,
  vehicleManufacturer: () => `${vehicleLookupStore.getProduct()}-manufacturer`,
  vehicleModel: () => `${vehicleLookupStore.getProduct()}-model`,
  vehicleModelDescription: () => `${vehicleLookupStore.getProduct()}-model-description`,
  carRegistrationSearch: "car-registration-search",
  carManufacturer: "car-manufacturer",
  carModel: "car-model",
  carModelDescription: "car-model-description",
  bikeRegistrationSearch: "bike-registration-search",
  bikeManufacturer: "bike-manufacturer",
  bikeModel: "bike-model",
  bikeModelDescription: "bike-model-description",
  vanRegistrationSearch: "van-registration-search",
  vanManufacturer: "van-manufacturer",
  vanModel: "van-model",
  vanModelDescription: "van-model-description",
  bikeLicenceTypeCar: "type-car",
  bikeDateLicenceObtainedCar: "date-licence-obt",
  bikeCarNCB: "car-ncb",
  motoringConvictionsRadio: "motoring-convictions-radio",
  accidentClaimsRadio: "accident-claims-radio",
  motoringConvictions: "motoring-convictions",
  accidentClaims: "accident-claims",
  motoringConvictionsRadioAdditional: "motoring-convictions-radio-additional",
  accidentClaimsRadioAdditional: "accident-claims-radio-additional",
  motoringConvictionsAdditional: "motoring-convictions-additional",
  accidentClaimsAdditional: "accident-claims-additional",
  vehiclesInHousehold: "household-vehicles",
  retrieveQuoteReference: "quote-reference",
  retrieveEmail: "quote-reference-email",
  retrievePostcode: "quote-reference-postcode",
  email: "email",
  touringManufacturerControlName: "touring-caravan-manufacturer",
  staticManufacturerControlName: "static-caravan-manufacturer",
  modelControlName: "caravan-model",
  yearManufactureControlName: "year-manufacture",
  caravanTypeControlName: "caravan-type",
  caravanModelDescriptionName: "caravan-model-description",
  caravanPostcodeName: "caravan-storage-postcode",
  caravanPostcodeDropdownControlName: "caravan-storage-postcode-dropdown",
  caravanStreetControlName: "caravan-storage-street-name",
  caravanTownControlName: "caravan-storage-town",
  caravanCityControlName: "caravan-storage-city",
  caravanHouseNumberManualControlName: "caravan-storage-house-number-name",
  caravanCountyControlName: "caravan-storage-county",
  caravanHouseNameHiddenControlName: "caravan-storage-house-name",
  caravanHouseNumberHiddenControlName: "caravan-storage-house-number",
  membershipIdRadioControlName: "membership-id-radio",
  incomeLifeCrossSell: "income-life-cross-sell",
  incomeLifeCrossSellMessageBox: "message-box-income-life-cross-sell",
});
