import controlNames from "@constants/controlNames";
import dateParser from "@helpers/dateParser";
import AppStore from "@store/AppStore";
import dayjs from "dayjs";

function manipulateControlsPreRender(controlName) {
  //Workaround in case user amends and changes their date of birth.
  //If the age criteria is not met, the control will not mount and clear its value so it will keep the old value
  if (controlName === controlNames.dateOfBirth) {
    AppStore.forceSetFormData(controlNames.incomeLifeCrossSell, "");
  }

  if (controlName === controlNames.incomeLifeCrossSell) {
    AppStore.deleteFormData(controlName);

    const dateOfBirth = AppStore.getControlByName(controlNames.dateOfBirth);
    const age = parseInt(dateParser.getDateDiffInYears(dateOfBirth, dayjs()));

    if (age >= 18 && age <= 65) {
      delete AppStore.forcedFormData[controlNames.incomeLifeCrossSell];

      AppStore.changeVisibilityControls(
        [controlNames.incomeLifeCrossSell, controlNames.incomeLifeCrossSellMessageBox],
        true
      );
    } else {
      AppStore.changeVisibilityControls(
        [controlNames.incomeLifeCrossSell, controlNames.incomeLifeCrossSellMessageBox],
        false
      );
    }
  }
}

export default {
  manipulateControlsPreRender,
};
