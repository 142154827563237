import { preserveQueryStringParams } from "@components/AppLink";
import DOMPurify from "dompurify";
import React, { Fragment, useRef } from "react";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import { useControllableState } from "../../hooks/useControllableState";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import NeedHelpPhone from "../controls/StaticControls/Phone.Component";
import SidebarV1 from "./SidebarV1";

/**
 * @param {Object} props - component Props
 * @param {Object} props.sidebar_info
 * @param {Boolean} [props.isOpened]
 * @param {((value: Boolean) => void)} [props.setIsOpened]
 * @param {Boolean} [props.defaultIsOpened]
 */
const SideBar = ({
  sidebar_info,
  defaultIsOpened: defaultIsOpenedProp = false,
  setIsOpened: setIsOpenedProp,
  isOpened: isOpenedProp,
}) => {
  const [isOpened, setIsOpened] = useControllableState({
    value: isOpenedProp,
    onChange: setIsOpenedProp,
    defaultValue: defaultIsOpenedProp,
  });
  const toggleContainer = () => setIsOpened((o) => !o);

  const sidebarContainerRef = useRef();
  useOutsideClick({
    enabled: isOpened,
    ref: sidebarContainerRef,
    handler: toggleContainer,
  });

  const sideBarVersion = sidebar_info.version;
  const sideBarRetrieveButton = !isNullOrUndefined(sidebar_info.button_retrieve_label)
    ? sidebar_info.button_retrieve_label
    : "Retrieve your quote here";
  const retrieveQuoteTitle = !isNullOrUndefined(sidebar_info.retrieveQuoteTitle)
    ? sidebar_info.retrieveQuoteTitle
    : "Already got a quote?";

  const NQSContent = (
    <div className="need-help-content-wrapper">
      <button
        type="button"
        className="btn help-area-questions-alternate"
        onClick={toggleContainer}>
        <span>
          <i className="fa fa-times" aria-hidden="true"></i>Close
        </span>
      </button>
      <div>
        <h3>{sidebar_info.textMain}</h3>
        <h3 className="phone-section">
          Call: <a href={`tel:${sidebar_info.phone}`}>{sidebar_info.phone}</a>
        </h3>
        <div className="opening-times">
          <h4>Opening times:</h4>
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(sidebar_info.openingTimes, {
                USE_PROFILES: { html: true },
                ADD_TAGS: ["span"],
              }),
            }}></p>
        </div>
      </div>
    </div>
  );

  const baseContent = (
    <Fragment>
      {sideBarVersion === "v2" ? null : (
        <div className="help-area-questions-alternate-container d-lg-none">
          <button
            type="button"
            className="btn help-area-questions-alternate"
            onClick={toggleContainer}>
            More help
          </button>
        </div>
      )}
      <div className="need-help-content-wrapper">
        <p className=" text-center title">{sidebar_info.title}</p>
        <p
          className=" text-center"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(sidebar_info.text, {
              USE_PROFILES: { html: true },
              ADD_TAGS: ["a", "p", "div", "span"],
              ADD_ATTR: ["href", "target", "name", "class"],
            }),
          }}></p>
        <div className="phone-container mt-2 mb-2">
          <NeedHelpPhone id="sidePhone" name="sidebar-phone" value={sidebar_info.phone} />
        </div>
        <p
          className=" text-center"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(sidebar_info.help, {
              USE_PROFILES: { html: true },
              ADD_TAGS: ["a", "p", "div", "span"],
              ADD_ATTR: ["href", "target", "name", "class"],
            }),
          }}></p>
      </div>
    </Fragment>
  );

  if (sideBarVersion === "v1") {
    return <SidebarV1 attributes={sidebar_info} />;
  } else {
    return (
      <React.Fragment>
        <div className="retrieve-container container text-center pt-3 pb-3 mb-3">
          <p className="text-center title">{retrieveQuoteTitle}</p>
          <a
            href={preserveQueryStringParams(
              `${import.meta.env.VITE_SITE_ROUTE}/default/`
            )}
            className={`button-container btn-retrieve-quote d-inline-block p-0`}>
            <p className="btn btn-link text-center">{sideBarRetrieveButton}</p>
            <i className={`fa fas fa-chevron-right ml-2`}></i>
          </a>
        </div>

        <div
          ref={sidebarContainerRef}
          id="sideBarContainer"
          data-testid="sideBarContainer"
          className={`help-sidebar-container container text-center pt-3 pb-3 ${
            isOpened ? "in" : ""
          }`}>
          {sideBarVersion === "nqs" ? NQSContent : baseContent}
        </div>
      </React.Fragment>
    );
  }
};

export default SideBar;
